import './frontend/styles/Global.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboard from './frontend/pages/Dashboard';
import Home from './frontend/pages/Home';
import Faqs from './frontend/pages/Faqs';

function App() {
  return (
    <div className="App">
        <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Dashboard/>} ></Route> 
                    <Route path='/chatbot' element={<Home/>}></Route>
                    <Route path='/faqs' element={<Faqs/>}></Route>
                </Routes>
            </BrowserRouter>
    </div>
  );
}

export default App;
