import React from 'react';
import { List } from 'phosphor-react';
// import logodark from '../images/Lightblacklogo.png'; // Adjust the path as necessary
import logodark from '../../images/intellikb light bg.png';
import logolight from '../../images/trransparentproper temp.png';
// import '../styles/Navbar.css';

const Navbar = ({ toggleSidebar, isLightTheme,isSidebarOpen }) => {


    const getNavbarClass = () => {
        return isSidebarOpen
            ? isLightTheme ? 'navbar-styled-light' : 'navbar-styled'
            : isLightTheme ? 'navbar-styled-light-without-sidebar' : 'navbar-styled-without-sidebar';
    };
    return (
        <div className={getNavbarClass()}>
        {/* // <div className={`home-styled ${isSidebarOpen ? 'home-styled-with-sidebar' : 'home-styled-without-sidebar'} ${isLightTheme ? 'home-styled-light' : ''}`}> */}

        {/* // <div className={`${isSidebarOpen ? `${isLightTheme ? 'navbar-styled-light-without-sidebar' : 'navbar-styled-without-sidebar'}` : 'navbar-styled-without-sidebar'} ${isLightTheme ? 'navbar-styled-light' : 'navbar-styled'}`}> */}
            <div style={{display:'flex', alignItems:'center' }}>
                <div className='listbutton'>
                    <List  onClick={toggleSidebar} size={20} />
                </div>

                {/* <p>Rombus</p> */}
            
                <img src={`${isLightTheme ? logolight : logodark} `} alt="Description of image" style={{ width: '110px', height: '40px',paddingLeft:'5px' }} />
                {/* <p style={{ textAlign: 'center', margin: 0,paddingLeft:'4px' }}>IntelliKB</p> */}
                {/* <img src={logodark} alt="Description of image" style={{ width: '130px', height: '40px'}} /> */}
            </div>
            
            <span>P</span>
        </div>
    );
}

export default Navbar;
