import React, { useState } from 'react';
import faqData from '../../Faqsdata.json';
import '../styles/Faqs.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/trransparentproper.png';
const Faqs = () => {
    
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };

    const navigate = useNavigate(); // Initialize the useNavigate hook
  
    const handleLogoClick = () => {
      navigate('/'); // Navigate to the Dashboard route
  };
return (
    <div>
    <nav>
        {/* <img src={logo} alt="" className="logo" /> */}
        <img src={logo} onClick={handleLogoClick} alt="logo on navbar" className="logoimg" />
        {/* <span className="logo" onClick={handleLogoClick}>IntelliKB</span> */}
        <button className="faqbtn-dark" ><b>Login</b></button>

    </nav>
    
    <div className="faqheader">
        <h1>IntelliKB Ai FAQ</h1>
        <div className="faq-outer" style={{ margin: '0px' }}>
          {faqData.faq.map((item, index) => (
            <div
              className={`faq-item ${activeIndex === index ? 'active' : ''}`}
              key={item.id}
              onClick={() => handleToggle(index)}
            >
              <h2>{item.question}<span style={{ fontSize: '14px' }} className="arrow">&#9660;</span></h2>
              {activeIndex === index && (
                <p>
                  {item.answer.split('\n').map((line, idx) => (
                    <React.Fragment key={idx}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
</div>

  )
}

export default Faqs