import React, { useEffect, useState } from 'react';
import '../styles/Dashboard.css';
import logo from '../../images/trransparentproper.png';
import lightlogo from '../../images/intellikb light bg.png'
// import videodash from '../images/intelidash.mp4';
import videodash from '../../images/chattingvideo.mp4';
import googlepnglogo from '../../images/social.png';
import { useNavigate } from 'react-router-dom';
import { GoogleLogo } from 'phosphor-react';

import logodark from '../../images/Lightblacklogo.png'; // Adjust the path as necessary





const Dashboard = () => {

    const [user, setUser] = useState(null);
//   const navigate = useNavigate();



    // const [user, setUser] = useState(null);

    useEffect(() => {
      // Load user data from localStorage
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        setUser(JSON.parse(storedUser));
      }
    }, []);
  
    const handleLogin = (userData) => {
      // Simulate login
      localStorage.setItem('user', JSON.stringify(userData));
      setUser(userData);
    };
  
    // const handleLogout = () => {
    //   // Simulate logout
    //   localStorage.removeItem('user');
    //   setUser(null);
    // };
    // 192.168.0.103
    
    const googleLogin = () => {
        window.open('http://localhost:3001/auth/google', '_self');
      };

    const toggleMenu = () => {
        const menuList = document.getElementById('menu-list');
        menuList.classList.toggle('show-menu');
    };

    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/'); 
    };


    
    
    const [isLightTheme, setIsLightTheme] = useState(true);

    const toggleTheme = () => {
        setIsLightTheme(!isLightTheme);
    };
    
    
    
    
    const [text, setText] = useState(''); // Initialize text state
    const fullText = [
    'Hello, User I am IntelliKB your AI assistant, how i can help you?'   
    ];

    useEffect(() => {
        let index = 0;
        let charIndex = 0;
        const typingSpeed = 50; // Speed of typing

        const typeText = () => {
            if (index < fullText.length) {
                if (charIndex < fullText[index].length) {
                    setText(fullText[index].slice(0, charIndex + 1)); // Set text to the current substring
                    charIndex++;
                    setTimeout(typeText, typingSpeed);
                } else {
                    // Sentence completed, reset charIndex and move to next sentence
                    charIndex = 0;
                    setTimeout(() => {
                        setText(""); // Clear text before starting next sentence
                        index++;
                        if (index < fullText.length) {
                            typeText(); // Continue typing next sentence
                        }
                        else {
                            // Loop back to the beginning
                            index = 0;
                            setTimeout(typeText, typingSpeed * 10); // Delay before starting again
                        }
                    }, typingSpeed * 10); // Delay before starting next sentence
                }
            }
        };

        setText(''); // Reset text before starting
        typeText();

        return () => setText(''); // Cleanup on component unmount
    }, []);






    return (
        <div className={`${isLightTheme ? 'lightdash' : ''} `}>
            <nav className={`${isLightTheme ? 'nav-light' : ''} `}>
                <img src={`${isLightTheme ? lightlogo : logo} `} alt="logo on navbar" className="logoimg" />
                {/* <img src= alt="logo on navbar" className="logoimg" /> */}
            
                {/* <span className="logo" onClick={handleLogoClick}>IntelliKB</span> */}
                <ul id="menu-list">
                    <li className="heading"><input type="checkbox" className="theme-checkbox" onChange={toggleTheme} checked={isLightTheme} /></li>
                    <li className="heading"><a href="/faqs">FAQs</a></li>
                </ul>
                <div onClick={toggleMenu} className={`${isLightTheme ? 'menu-icon-light' : 'menu-icon'} `}>&#9776;</div>
            </nav>
            <div  className={`${isLightTheme ? 'header-light' : 'header'} `}>
                
                <div className="video-content small-video">
                    <video autoPlay loop muted>
                        <source src={videodash} type="video/mp4" />
                    </video>
                </div>


                <div className="text-content">
                    <h1><span>IntelliKB</span><br /><span></span></h1>
                    <div className={`${isLightTheme ? 'animated-text-container-light' : 'animated-text-container'} `}>
                    <img src={logodark} alt="Description of image" className="response-logo" />
                    <div className={`${isLightTheme ? 'animated-text-light' : 'animated-text'} `}>{text}</div>
                    </div>
                    <h2>Your AI-driven chatbot for instant knowledgebase support</h2>
                    
                    <h4 style={{paddingBottom:'10px'}}>
                    Chat to empower your customer's journey with your knowledge
                       
                    </h4>
                    
                    <h5>
                    Click on login to continue with your
                    {/* <br /> */}
                        <span style={{ color: '#1669eb' ,fontSize:'18px' }}> G</span>
                        <span style={{ color: '#d03227' ,fontSize:'16px'}}>o</span>
                        <span style={{ color: '#e2a525' ,fontSize:'16px'}}>o</span>
                        <span style={{ color: '#1669eb' ,fontSize:'16px'}}>g</span>
                        <span style={{ color: '#15954e' ,fontSize:'16px'}}>l</span>
                        <span style={{ color: '#d03227' ,fontSize:'16px'}}>e</span> account
                    </h5>
                    {/* <span style={{color:'grey'}}>Click on login to Continue with your</span> */}
                        {/* bellow is the text animation styyle  */}
                      

                    <div className="btn-container">
                        <button className={`${isLightTheme ? 'btn-light' : 'btn-dark'} `} onClick={googleLogin}><b>Login</b></button>
                    </div>
                </div>
                <div className="video-content">
                    <video autoPlay loop muted>
                        <source src={videodash} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
