import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { ArrowArcRight, ArrowBendDoubleUpRight, ArrowBendDownRight, ArrowCircleDownRight, ArrowCircleRight, ArrowClockwise, Copy, Copyleft, Copyright, CopySimple, ThumbsDown, ThumbsUp } from 'phosphor-react';
import '../styles/Newloader.css';

import Loader from './Loader';

import { CodeSimple, Lightbulb, Lightning, PencilSimple } from 'phosphor-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { marked } from 'marked';
import DOMPurify from 'dompurify';

// const handleNotHelpfulClick = async () => {
//     try {
//         const feedback = 'Not Helpful';
//         // http://localhost:3001
        
//         // await axios.post('http://192.168.0.103:3001/feedbacknode', {
//         await axios.post('http://192.168.0.103:3001/feedbacknode', {
//           feedback: feedback
//         });
//         console.log('Feedback sent successfully');
//       } catch (error) {
//         console.error('Error sending feedback:', error);
//       }
// };

// const handleHelpfulClick = async () => {
//     try {
//       const feedback = 'Helpful';
//       await axios.post('http://192.168.0.103:3001/feedbacknode', {
//         feedback: feedback
//       });
//       console.log('Feedback sent successfully');
//     } catch (error) {
//       console.error('Error sending feedback:', error);
//     }
// };

const handleNotHelpfulClick = async (srNo) => {
    try {
        const feedback = 'Not Helpful';
        await axios.post('http://192.168.0.103:3001/feedbacknode', {
          feedback: feedback,
          srNo: srNo
        });
        console.log('Feedback sent successfully');
      } catch (error) {
        console.error('Error sending feedback:', error);
      }
};

const handleHelpfulClick = async (srNo) => {
    try {
      const feedback = 'Helpful';
      await axios.post('http://192.168.0.103:3001/feedbacknode', {
        feedback: feedback,
        srNo: srNo
      });
      console.log('Feedback sent successfully');
    } catch (error) {
      console.error('Error sending feedback:', error);
    }
};

const Home = () => {
    const [user, setUser] = useState(null);
    const navigate = useNavigate();
    const [fetchSessionTitles, setFetchSessionTitles] = useState(null);
    const [userInput, setUserInput] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [isFirstMessage, setIsFirstMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [previousChats, setPreviousChats] = useState([]);
    const [sessionId, setSessionId] = useState('');
    const [isLightTheme, setIsLightTheme] = useState(true);



    const [lastQuestion, setLastQuestion] = useState(""); // New state to store the last question

    const [currentResponseIndex, setCurrentResponseIndex] = useState(0); // Track the current response index

    // State declarations...
    const messageEndRef = useRef(null);

    // Function to scroll to the bottom of the messages
    const scrollToBottom = () => {
        if (messageEndRef.current) {
            messageEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        scrollToBottom(); // Scroll to bottom whenever chatHistory changes
    }, [chatHistory]);
    // useEffect(() => {
    //     scrollToBottom(); // Scroll to bottom whenever chatHistory changes
    // }, [chatHistory]);


    useEffect(() => {
        setChatHistory([]);
        setIsFirstMessage(false);
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const userid = queryParams.get('id');
        const name = queryParams.get('name');
        const email = queryParams.get('email');

        if (userid && name && email) {
            const userData = {
                userid,
                name: JSON.parse(decodeURIComponent(name)),
                email,
            };
            localStorage.setItem('user', JSON.stringify(userData));
            setUser(userData);
            window.history.replaceState({}, document.title, "/chatbot");
        } else {
            const storedUser = localStorage.getItem('user');
            if (storedUser) {
                setUser(JSON.parse(storedUser));
            }
        }
    }, []);

    const toggleTheme = () => {
        setIsLightTheme(!isLightTheme);
    };

    useEffect(() => {
        const storedChatHistory = JSON.parse(localStorage.getItem('chatHistory'));
        const storedPreviousChats = JSON.parse(localStorage.getItem('previousChats'));
        if (storedChatHistory) setChatHistory(storedChatHistory);
        if (storedPreviousChats) setPreviousChats(storedPreviousChats);
    }, []);

    useEffect(() => {
        localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
        localStorage.setItem('previousChats', JSON.stringify(previousChats));
    }, [chatHistory, previousChats]);

    useEffect(() => {
        const fetchChatHistory = async () => {
            try {
                const response = await fetch(`http://192.168.0.103:3001/chat/${user?.userid}/${sessionId}`);
                if (response.ok) {
                    let chats = await response.json();
                    chats = chats.map(chat => ({
                        ...chat,
                        isTitle: !!chat.title,
                        isMessage: !!chat.message
                    }));
                    setChatHistory(chats);
                } else {
                    console.error('Failed to fetch chat history');
                }
            } catch (error) {
                console.error('Error fetching chat history:', error);
            }
        };

        if (sessionId) fetchChatHistory();
    }, [user?.userid, sessionId]);

    const onSessionTitleClick = (sessionId) => {
        setIsFirstMessage(true);
        setSessionId(Number(sessionId));
    };

    useEffect(() => {
        if (fetchSessionTitles) {
            console.log('fetchSessionTitles function is set');
        } else {
            console.error('fetchSessionTitles is not set');
        }
    }, [fetchSessionTitles]);

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     const userMessage = { message: userInput, isUser: true };
    //     setChatHistory(prevChatHistory => [...prevChatHistory, userMessage]);
    //     setIsFirstMessage(true);
    //     setIsLoading(true);

    //     try {
    //         const response = await fetch('http://localhost:3001/ask', {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({ question: userInput })
    //         });

    //         const data = await response.json();
    //         const botMessageContent = data.answer.greeting_response || data.answer.geminiresponse || data.answer.notsimillar || data.answer.OnlyMarkedAnswer;

    //         const botMessage = {
    //             message: botMessageContent,
    //             greetmessage: data.answer.response || '',
    //             gemresponse: data.answer.geminiresponse || '',
    //             category: data.answer.category || '',
    //             technology: data.answer.technology || '',
    //             onlyMarkedAnswer: data.answer.only_marked_answer || '',
    //             tips: data.answer.Tip || '',
    //             blogLink: data.answer.Blog_Link || '',
    //             imageLink: data.answer.Image || '',
    //             owner: data.answer.Owner || '',
    //             relatedAnswer: data.answer.Related_Answer || '',
    //             relatedLink: data.answer.Related_Link || '',
    //             status: data.answer.Status || '',
    //             lastResolved: data.answer.Last_Resolved || '',
    //             projectReference: data.answer.Project_Reference || '',
    //             searchCount: data.answer.Search_Count || '',
    //             isUser: false
    //         };

    //         setChatHistory(prevChatHistory => [...prevChatHistory, botMessage]);

    //         await fetch('http://localhost:3001/chat', {
    //             method: 'POST',
    //             headers: { 'Content-Type': 'application/json' },
    //             body: JSON.stringify({
    //                 sessionId,
    //                 userId: user?.userid,
    //                 title: userMessage.message,
    //                 message: botMessage.message,
    //                 greetresponse: botMessage.greetmessage,
    //                 category: botMessage.category,
    //                 technology: botMessage.technology,
    //                 onlyMarkedAnswer: botMessage.onlyMarkedAnswer,
    //                 tips: botMessage.tips,
    //                 blogLink: botMessage.blogLink,
    //                 imageLink: botMessage.imageLink,
    //                 owner: botMessage.owner,
    //                 relatedAnswer: botMessage.relatedAnswer,
    //                 relatedLink: botMessage.relatedLink,
    //                 status: botMessage.status,
    //                 lastResolved: botMessage.lastResolved,
    //                 projectReference: botMessage.projectReference,
    //                 searchCount: botMessage.searchCount,
    //             })
    //         });

    //     } catch (error) {
    //         console.error('Error:', error);
    //         const botMessage = { message: "There was an error processing your request.", isUser: false };
    //         setChatHistory(prevChatHistory => [...prevChatHistory, botMessage]);
    //     }

    //     setIsLoading(false);
    //     setUserInput("");
    // };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const userMessage = { message: userInput, isUser: true };
        setChatHistory(prevChatHistory => [...prevChatHistory, userMessage]);
        setIsFirstMessage(true);
        setIsLoading(true);
    
        setLastQuestion(userInput);  // Store the last question
    
        try {
            const response = await fetch('http://192.168.0.103:3001/ask', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ question: userInput,regenerate: false  })
                 // Set regenerate flag to true
            });
    
            const data = await response.json();
            const botMessageContent = data.answer.greeting_response || data.answer.geminiresponse || data.answer.notsimillar || data.answer.OnlyMarkedAnswer;
    
            const botMessage = {
                message: botMessageContent,
                greetmessage: data.answer.response || '',
                gemresponse: data.answer.geminiresponse || '',
                category: data.answer.category || '',
                technology: data.answer.technology || '',
                onlyMarkedAnswer: data.answer.only_marked_answer || '',
                tips: data.answer.Tip || '',
                blogLink: data.answer.Blog_Link || '',
                imageLink: data.answer.Image || '',
                owner: data.answer.Owner || '',
                relatedAnswer: data.answer.Related_Answer || '',
                relatedLink: data.answer.Related_Link || '',
                status: data.answer.Status || '',
                lastResolved: data.answer.Last_Resolved || '',
                projectReference: data.answer.Project_Reference || '',
                searchCount: data.answer.Search_Count || '',
                SrNo:data.answer.SrNo ||'',
                isUser: false
            };
            
    
            setChatHistory(prevChatHistory => [...prevChatHistory, botMessage]);
    
            await fetch('http://192.168.0.103:3001/chat', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    sessionId,
                    userId: user?.userid,
                    title: userMessage.message,
                    message: botMessage.message,
                    greetresponse: botMessage.greetmessage,
                    category: botMessage.category,
                    technology: botMessage.technology,
                    onlyMarkedAnswer: botMessage.onlyMarkedAnswer,
                    tips: botMessage.tips,
                    blogLink: botMessage.blogLink,
                    imageLink: botMessage.imageLink,
                    owner: botMessage.owner,
                    relatedAnswer: botMessage.relatedAnswer,
                    relatedLink: botMessage.relatedLink,
                    status: botMessage.status,
                    lastResolved: botMessage.lastResolved,
                    projectReference: botMessage.projectReference,
                    searchCount: botMessage.searchCount,
                    SrNo: botMessage.SrNo,
                })
            });
            scrollToBottom();  // Scroll to bottom after new messages are added
        } catch (error) {
            console.error('Error:', error);
            const botMessage = { message: "There was an error processing your request.", isUser: false };
            setChatHistory(prevChatHistory => [...prevChatHistory, botMessage]);
            scrollToBottom();  // Scroll to bottom after error message is added
        }
    
        setIsLoading(false);
        setUserInput("");
        // scrollToBottom();  // Ensure this is called after all state updates
    };
    

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const reusablefetchMaxSessionId = async () => {
        try {
            const response = await fetch(`http://192.168.0.103:3001/chat/${user?.userid}`);
            const chats = await response.json();

            if (chats && chats.length > 0) {
                const maxSessionId = Math.max(...chats.map(chat => Number(chat.sessionId)));
                return maxSessionId;
            } else {
                return 0;
            }
        } catch (error) {
            console.error('Error fetching session ID:', error);
            return 0;
        }
    };

    useEffect(() => {
        const initializeSessionId = async () => {
            const maxSessionId = await reusablefetchMaxSessionId();
            setSessionId(maxSessionId + 1);
        };

        if (user?.userid) {
            initializeSessionId();
        }
    }, [user?.userid]);

    const handleNewChat = async () => {
        if (!fetchSessionTitles) {
            console.error('fetchSessionTitles is not set, cannot create a new chat.');
            return;
        }
    

        if (chatHistory.length > 0) {
            setPreviousChats(prev => [...prev, chatHistory]);
            const maxSessionId = await reusablefetchMaxSessionId();
            setSessionId(maxSessionId + 1); // Set new session ID based on maxSessionId + 1
            setChatHistory([]);
            setIsFirstMessage(false);
    
            try {
                await fetchSessionTitles();  // Ensure this is defined
            } catch (error) {
                console.error('Error calling fetchSessionTitles:', error);
            }
        }
    };

    const isCodeSnippet = (text) => {
        // This function is a simple way to detect if the response might be a code snippet.
        return text && (text.includes('```') || text.includes('{') || text.includes('function') || text.includes('const') || text.includes('let'));
    };

    // const handleCopyClick = (text) => {
    //     navigator.clipboard.writeText(text)
    //         .then(() => {
    //             console.log('Text copied to clipboard');
    //         })
    //         .catch(err => {
    //             console.error('Failed to copy text: ', err);
    //         });
    // };

    


    const parseBoldTextWithIndentation = (text) => {
        const regex = /\*\*(.*?)\*\*/g;
        const parts = text.split(regex);

        return parts.map((part, index) => {
            if (index % 2 === 1) {
                return <strong key={index}>{part}</strong>;
            }
            // Indent if the line starts with "* **"
            if (part.trim().startsWith("* **")) {
                return (
                    <p key={index} style={{ marginLeft: '20px' }}>
                        {part.split('/n').map((line, i) => (
                            <React.Fragment key={i}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </p>
                );
            }
            return part.split('/n').map((line, i) => (
                <React.Fragment key={i}>
                    {line}
                    <br />
                </React.Fragment>
            ));
        });
    };

    const renderResponse = (response) => {
        const parts = response.split(/(```[\s\S]*?```)/g); // Split by code blocks

        return parts.map((part, index) => {
            if (part.startsWith("```") && part.endsWith("```")) {
                const languageMatch = part.match(/```(\w+)?/);
                const language = languageMatch ? languageMatch[1] : "javascript";
                const code = part.replace(/```(\w+)?/, "").replace(/```$/, ""); // Remove the ``` wrapper
                return (
                    <SyntaxHighlighter
                        key={index}
                        language={language}
                        style={a11yDark}
                        wrapLongLines={true}
                        wrapLines={true}
                    >
                        {code}
                    </SyntaxHighlighter>
                );
            }
            return (
                <p key={index}>
                    {parseBoldTextWithIndentation(part)}
                </p>
            );
        });
    };

    
    const renderMarkdown = (markdownText) => {
        const htmlContent = marked(markdownText);
        const sanitizedHtml = DOMPurify.sanitize(htmlContent);
        return { __html: sanitizedHtml };
    };


    // const handleRegenerateClick = async () => {
    //     if (!lastQuestion) {
    //         console.error('No question to regenerate.');
    //         return;
    //     }
    
    //     setIsLoading(true);
    
    //     try {
    //         const response = await axios.post('http://localhost:3001/ask', {
    //             question: lastQuestion,  // Pass the last question
    //             regenerate: true  // Set regenerate flag to true
    //         });
    
    //         const newAnswer = response.data.answer;
    //         const botMessageContent = newAnswer.geminiresponse || newAnswer.greeting_response || newAnswer.notsimillar || newAnswer.only_marked_answer;
    
    //         // Update the last bot message in the chat history
    //         setChatHistory(prevChatHistory => {
    //             const updatedHistory = [...prevChatHistory];
    //             updatedHistory[updatedHistory.length - 1] = {
    //                 ...updatedHistory[updatedHistory.length - 1],
    //                 message: botMessageContent,
    //             };
    //             return updatedHistory;
    //         });
    //     } catch (error) {
    //         console.error('Error regenerating answer:', error);
    //     }
    
    //     setIsLoading(false);
    // };
   
//    proper working the bellow code on14/8/24
    // const handleRegenerateClick = async () => {
    //     try {
    //         const response = await axios.post('http://localhost:3001/ask', {
    //             question: lastQuestion,  // Pass the last question
    //             regenerate: true  // Set regenerate flag to true
    //         });
    
    //         const newAnswer = response.data.answer;
            
    //         setChatHistory(prevChatHistory => [...prevChatHistory, { message: newAnswer, isUser: false }]);
    //     } catch (error) {
    //         console.error('Error regenerating answer:', error);
    //     }
    // };
    
    
    const handleRegenerateClick = async () => {
        if (!lastQuestion) {
            console.error('No question to regenerate.');
            return;
        }
    
        setIsLoading(true);
    
        try {
            const response = await axios.post('http://192.168.0.103:3001/ask', {
                question: lastQuestion,  // Pass the last question
                regenerate: true  // Set regenerate flag to true
            });
    
            const newAnswer = response.data.answer;
            const botMessageContent = newAnswer.geminiresponse || newAnswer.greeting_response || newAnswer.notsimillar || newAnswer.only_marked_answer;
    
            const botMessage = {
                message: botMessageContent,
                SrNo:response.data.answer.SrNo ||'',
                isUser: false,
            };
    
            setChatHistory(prevChatHistory => [...prevChatHistory, botMessage]);
    
        } catch (error) {
            console.error('Error regenerating answer:', error);
            const botMessage = { message: "There was an error processing your request.", isUser: false };
            setChatHistory(prevChatHistory => [...prevChatHistory, botMessage]);
        }
    
        setIsLoading(false);
    };
    const handleCopyClick = (text) => {
        if (text) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    console.log('Text copied to clipboard');
                })
                .catch(err => {
                    console.error('Failed to copy text: ', err);
                });
        } else {
            console.error('No text available to copy');
        }
    };
    
    
    
    

    return (
        <div style={{ backgroundColor: isLightTheme ? 'white' : 'black' }}>
            <div className={`${isSidebarOpen ? (isLightTheme ? 'home-styled-light' : 'home-styled') : (isLightTheme ? 'home-styled-light-without-sidebar' : 'home-styled-without-sidebar')}`}>
                <Navbar toggleSidebar={toggleSidebar} isLightTheme={isLightTheme} isSidebarOpen={isSidebarOpen} />
                {/* <Sidebar
                    isOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                    handleNewChat={handleNewChat}
                    previousChats={previousChats}
                    onSessionTitleClick={onSessionTitleClick}
                    onFetchSessionTitles={setFetchSessionTitles}
                    isLightTheme={isLightTheme}
                    toggleTheme={toggleTheme}
                /> */}
                <Sidebar
                    isOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                    handleNewChat={handleNewChat}
                    previousChats={previousChats}
                    onSessionTitleClick={onSessionTitleClick}
                    onFetchSessionTitles={setFetchSessionTitles}  // This should be correct
                    isLightTheme={isLightTheme}
                    toggleTheme={toggleTheme}
                />

                <div className='content'>
                    {!isFirstMessage && (
                        <>
                            <div className='title'>
                                <h1>Hello, {user && user.name ? user.name.givenName : 'User'}</h1>
                                <h2 className={`${isLightTheme ? 'lighttitle' : ''}`}>How can I help you today?</h2>
                            </div>
                            <div className={`${isLightTheme ? 'grid-light' : 'grid'}`}>
                                <div className='item'>
                                    <p>Come up with a product name for a new app</p>
                                    <span><PencilSimple weight='bold' size={25} /></span>
                                </div>
                                <div className='item'>
                                    <p>Help explain a concept in a kid-friendly way</p>
                                    <span><Lightbulb weight='bold' size={25} /></span>
                                </div>
                                <div className='item'>
                                    <p>Brainstorm ideas for a mocktail given specific ingredients</p>
                                    <div><span><Lightning weight='bold' size={25} /></span></div>
                                </div>
                                <div className='item'>
                                    <p>Look up a Linux shell command for a specific task</p>
                                    <span><CodeSimple weight='bold' size={25} /></span>
                                </div>
                            </div>
                        </>
                    )}

                    {isFirstMessage && chatHistory.map((chat, index) => {
                        const isLastMessage = index === chatHistory.length - 1;

                        const fullMessage = `
                            ${chat.owner ? `Owner: ${chat.owner}\n` : ''}
                            ${chat.category ? `Category: ${chat.category}\n` : ''}
                            ${chat.technology ? `Technology: ${chat.technology}\n` : ''}
                            ${chat.onlyMarkedAnswer ? `${chat.onlyMarkedAnswer}\n` : ''}
                            ${chat.message || ''}
                            ${chat.tips ? `Tip: ${chat.tips}\n`:''}
                            ${chat.blogLink ? `Bloglink: ${chat.blogLink}\n`:''}
                            ${chat.imageLink ? `Imagelink: ${chat.imageLink}\n`:''}
                            ${chat.relatedAnswer ? `Related Answer: ${chat.relatedAnswer}\n`:''}
                            ${chat.relatedLink ? `Related Link: ${chat.relatedLink}\n`:''}
                            ${chat.status ? `Status: ${chat.status}\n`:''}
                            ${chat.lastResolved ? `last resolved: ${chat.lastResolved}\n`:''}
                            ${chat.projectReference ? `project refersnce: ${chat.projectReference}\n`:''}
                        `.trim();

                        return (
                            <React.Fragment key={index}>
                                {chat.title && (
                                    <div className={`${chat.isUser ? 'message user' : 'message user'}`}>
                                        <p>{chat.title}</p>
                                    </div>
                                )}

                                <div className={chat.isUser ? 'message user' : 'message bot'}>
                                    {!chat.isUser && (
                                        <div className='bot-message'>
                                            {(chat.owner || chat.category || chat.technology) && (
                                                <div className="bot-message-header">
                                                    {chat.owner && <p><strong>Owner:</strong> {chat.owner}</p>}
                                                    {chat.category && <p><strong>Category:</strong> {chat.category}</p>}
                                                    {chat.technology && <p><strong>Technology:</strong> {chat.technology}</p>}
                                                </div>
                                            )}
                                            {(chat.onlyMarkedAnswer) && (
                                                <div className="bot-message-body">
                                                    <div className="marked-answer">
                                                        {renderResponse(chat.onlyMarkedAnswer)}
                                                        {chat.tips && <p><strong>Tip:</strong> {chat.tips}</p>}
                                                        {chat.blogLink && <p><strong >Blog Link:</strong><a href={chat.blogLink}>{chat.blogLink}</a></p>}
                                                {chat.imageLink && <p><strong>Image Link:</strong> <a href={chat.imageLink}>{chat.imageLink}</a></p>}
                                                {chat.relatedAnswer && <p><strong>Related Answer:</strong> {chat.relatedAnswer}</p>}
                                                {chat.relatedLink && <p><strong>Related Link:</strong><a href={chat.relatedLink} target="_blank" rel="noopener noreferrer">{chat.relatedLink}</a></p>}
                                                {chat.status && <p><strong>Status:</strong> {chat.status}</p>}
                                                {chat.lastResolved && <p><strong>Last Resolved:</strong> {chat.lastResolved}</p>}
                                                {chat.projectReference && <p><strong>Project Reference:</strong> {chat.projectReference}</p>}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className='message-content'>
                                        {typeof chat.message === 'string' ? (
                                            renderResponse(chat.message)
                                        ) : (
                                            <p></p>
                                        )}
                                        {chat.bullets && chat.bullets.length > 0 && (
                                            <ul className="custom-bullets">
                                                {chat.bullets.map((bullet, j) => (
                                                    <li key={j}><span className="bullet">•</span> {bullet}</li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    {/* {isLastMessage && !chat.isUser && ( */}
                                    {!chat.isUser && (
                                            <div className="bot-message-footer">
                                                {/* <div className="tooltip-container" onClick={handleHelpfulClick}>
                                                    <span className="response-icon good-response" role="img" aria-label="Helpful">
                                                        <ThumbsUp />
                                                    </span>
                                                    <span className="tooltip-text">Helpful</span>
                                                </div>
                                                <div className="tooltip-container" onClick={handleNotHelpfulClick}>
                                                    <span className="response-icon bad-response" role="img" aria-label="Not Helpful">
                                                        <ThumbsDown />
                                                    </span>
                                                    <span className="tooltip-text">Not Helpful</span>
                                                </div> */}
                                                <div className="tooltip-container" onClick={() => handleHelpfulClick(chat.SrNo)}>
                                                    <span className="response-icon good-response" role="img" aria-label="Helpful">
                                                        <ThumbsUp />
                                                    </span>
                                                    <span className="tooltip-text">Helpful</span>
                                                </div>
                                                <div className="tooltip-container" onClick={() => handleNotHelpfulClick(chat.SrNo)}>
                                                    <span className="response-icon bad-response" role="img" aria-label="Not Helpful">
                                                        <ThumbsDown />
                                                    </span>
                                                    <span className="tooltip-text">Not Helpful</span>
                                                </div>


                                                {/* onClick={() => handleRegenerateClick(index)} */}
                                                <div className="tooltip-container" >
                                                    <span className="response-icon" style={{ color: 'white' }} onClick={handleRegenerateClick} role="img" aria-label="Regenerate">
                                                        <ArrowClockwise />
                                                    </span>
                                                    <span className="tooltip-text">Regenerate</span>
                                                </div>



                                                <div className="tooltip-container" onClick={() => handleCopyClick(fullMessage)}>
                                                    <span className="response-icon" style={{ color: 'white' }} role="img" aria-label="Copy">
                                                        <Copy />
                                                    </span>
                                                    <span className="tooltip-text">Copy</span>
                                                </div>

                                            </div>
                                        )}
                                </div>
                            </React.Fragment>
                        );
                    })}
                    {isLoading && (
                        <div className="loader">
                            <hr style={{width:'70%'}} />
                            <hr style={{width:'70%'}} />
                            <hr style={{width:'55%'}} />
                        </div>
                    )}
                    {/* The reference point for auto-scroll */}
                    <div ref={messageEndRef} />
                </div>
                <div className={`${isLightTheme ? 'search-styled-light' : 'search-styled'}`} >
                    <form className={`${isLightTheme ? 'input-container-light' : 'input-container'}`} onSubmit={handleSubmit}>
                        <input
                            autoFocus
                            type="text"
                            placeholder="Enter a prompt here"
                            value={userInput}
                            onChange={(e) => setUserInput(e.target.value)}
                        />
                        <button type='submit' disabled={!userInput.trim() || isLoading}>
                            <ArrowCircleRight weight="bold" size={25} />
                        </button>
                    </form>
                    <p className="warning">
                        IntelliKB Ai may display inaccurate info, including about people, so double-check its responses.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Home;
