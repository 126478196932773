import React, { useState, useEffect, useRef } from 'react';
import { Chat, Question, Plus, List, Download, Gear } from 'phosphor-react';
import '../styles/Sidebar.css';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import logodark from '../../images/intellikb light bg.png';
import lightlogo from '../../images/intellikb light bg.png'

const Sidebar = ({ isOpen, toggleSidebar, handleNewChat, previousChats, onSessionTitleClick, isLightTheme, toggleTheme, onFetchSessionTitles }) => {
    console.log('Rendering Sidebar component');
   
    const navigate = useNavigate();
    const [sessionTitles, setSessionTitles] = useState([]);
    const [userId, setUserId] = useState('');
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const settingsRef = useRef(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            const parsedUser = JSON.parse(storedUser);
            if (parsedUser.userid !== userId) {
                console.log('Fetched userId:', parsedUser.userid);
                setUserId(parsedUser.userid);
            }
        }
    }, []);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (settingsRef.current && !settingsRef.current.contains(event.target)) {
                setIsSettingsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [settingsRef]);
    const handleSettingsClick = () => {
        setIsSettingsOpen(!isSettingsOpen);
    };


    // useEffect(() => {
    //     if (userId) {
    //         fetchSessionTitles();
    //     }
    // }, [userId]);

    // useEffect(() => {
    //     if (onFetchSessionTitles && typeof fetchSessionTitles === 'function') {
    //         onFetchSessionTitles(fetchSessionTitles);
    //     }
    // }, [onFetchSessionTitles, userId]);
    useEffect(() => {
        if (onFetchSessionTitles && typeof onFetchSessionTitles === 'function') {
            onFetchSessionTitles(fetchSessionTitles);
        }
    }, [onFetchSessionTitles, userId]);

    const handlefaqsClick = () => {
        navigate('/faqs');
    };

    const fetchSessionTitles = async () => {
        if (!userId) {
            console.error('User ID is not set');
            return;
        }
        try {
            const response = await fetch(`http://192.168.0.103:3001/chat/${userId}`);
            if (!response.ok) {
                throw new Error('Failed to fetch session titles');
            }
            const data = await response.json();
    
            const sessionTitleMap = new Map();
            data.forEach(chat => {
                if (!sessionTitleMap.has(chat.sessionId)) {
                    const trimmedTitle = chat.title.trim();
                    const truncatedTitle = trimmedTitle.substring(0, 22); // Limit title to 30 characters
                    sessionTitleMap.set(chat.sessionId, truncatedTitle);
                }
            });
    
            const uniqueSessionTitles = Array.from(sessionTitleMap.entries());
            setSessionTitles(uniqueSessionTitles);
        } catch (error) {
            console.error('Error fetching session titles:', error);
        }
    };

    
    
     

    const handledownloadClick = async () => {
        try {
            const response = await fetch(`http://192.168.0.103:3001/downloadchat/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const chatLogs = await response.json();

                const doc = new jsPDF();
                doc.setFontSize(18);
                doc.text('Chat History', 14, 22);

                const tableColumn = ["Timestamp", "Title", "Message"];
                const tableRows = [];

                chatLogs.forEach(chat => {
                    const chatData = [
                        new Date(chat.timestamp).toLocaleString(),
                        chat.title,
                        chat.message,
                    ];
                    tableRows.push(chatData);
                });

                doc.autoTable({
                    head: [tableColumn],
                    body: tableRows,
                    startY: 30,
                    styles: {
                        cellPadding: 3,
                        fontSize: 10,
                    },
                    columnStyles: {
                        0: { cellWidth: 30 },
                        1: { cellWidth: 30 },
                        2: { cellWidth: 'auto' },
                    },
                    didDrawCell: (data) => {
                        const cell = data.cell;
                        if (cell && cell.raw && cell.raw.length > 50) {
                            cell.styles.cellWidth = 'wrap';
                        }
                    },
                });

                doc.save('chat-history.pdf');
            } else {
                console.error('Failed to download chats');
            }
        } catch (error) {
            console.error('Error downloading chats:', error);
        }
    };

    const toggleSidebarFromSidebar = () => {
        toggleSidebar();
    };

    return (
        <aside className={`sidebar-styled ${isOpen ? 'open' : 'closed'} ${isLightTheme ? 'sidebar-styled-light' : ''}`}>
            <div style={{ alignItems: 'center' }}>
                <div className='themesetting'>
                    <List weight='bold' className='hiddenlist' size={25} onClick={toggleSidebarFromSidebar} />
                    {/* <input type="checkbox" className="theme-checkbox" onChange={toggleTheme} checked={isLightTheme} /> */}
                </div>

                {/* <div className={`${isLightTheme ? 'sidebarlogolight' : 'sidebarlogo'}`} style={{alignItems:'center'}}> */}
                    {/* <img src={logodark} alt="Description of image" style={{ width: '130px', height: '40px'}} /> */}
                    {/* <p style={{ textAlign: 'center', margin: 0, paddingLeft: '8px' }}>IntelliKB</p> */}
                {/* </div> */}

                <div className={`${isLightTheme ? 'buttonnewchat' : 'buttonnewchat'}`}>
                    <button onClick={handleNewChat}><Plus weight='bold' size={20} />
                        <span>New chat</span>
                    </button>
                </div>
                <div style={{ padding: '16px', paddingTop: '10px', paddingBottom: '5px' }}><span>Recent</span></div>
                <div className='recents'>
                    {sessionTitles.map(([sessionId, title], index) => (
                        <div key={index} className={`${isLightTheme ? 'recent-chat-light' : 'recent-chat'}`} style={{ alignItems: 'center' }} onClick={() => onSessionTitleClick(sessionId)}>
                            <Chat weight='bold' size={20} /> <span style={{ textAlign: 'left' }}>{title}</span>
                            
                        </div>
                    ))}
                </div>
            </div>
            <div className='sidebar-bottom'>
                <div onClick={handledownloadClick}>
                    <Download weight='bold' size={20} />
                    <span>Download Chats</span>
                </div>
                <div onClick={handlefaqsClick}>
                    <Question weight='bold' size={20} />
                    <span>FAQS</span>
                </div>
                
                    <div style={{ display: 'flex', alignItems: 'center' }}>  
                        <div onClick={handleSettingsClick} style={{ display: 'flex', alignItems: 'center' }}>
                            <Gear weight='bold' size={20} />
                            <span>Settings</span>
                        </div>
                    </div>
                <p className='messagemct'>Code by @MCT IT Solutions</p>
                </div>
                {isSettingsOpen && (
                    <div ref={settingsRef} className="settings-card">
                        <h3>Settings</h3>
                        <div className='themesetting'>
                           <div className='themetext'>Theme:</div>
                            <input type="checkbox" className="theme-checkbox" onChange={toggleTheme} checked={isLightTheme} />
                        </div>
                    </div>
                )}
        </aside>
    );
};

export default Sidebar;
// import React, { useState, useEffect } from 'react';
// import { Chat, Question, Plus, List, Download, Gear } from 'phosphor-react';
// import '../styles/Sidebar.css';
// import { useNavigate } from 'react-router-dom';
// import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';
// import logodark from '../images/intellikb light bg.png';
// import lightlogo from '../images/intellikb light bg.png';

// const Sidebar = ({ isOpen, toggleSidebar, handleNewChat, previousChats, onSessionTitleClick, isLightTheme, toggleTheme, onFetchSessionTitles }) => {
//     const navigate = useNavigate();
//     const [sessionTitles, setSessionTitles] = useState([]);
//     const [userId, setUserId] = useState('');
//     const [isSettingsOpen, setIsSettingsOpen] = useState(false);

//     useEffect(() => {
//         const storedUser = localStorage.getItem('user');
//         if (storedUser) {
//             const parsedUser = JSON.parse(storedUser);
//             if (parsedUser.userid !== userId) {
//                 setUserId(parsedUser.userid);
//             }
//         }
//     }, [userId]);

//     const handleSettingsClick = () => {
//         setIsSettingsOpen(!isSettingsOpen);
//     };

//     const handlefaqsClick = () => {
//         navigate('/faqs');
//     };

//     const fetchSessionTitles = async () => {
//         if (!userId) return;

//         try {
//             const response = await fetch(`https://mct-chatbot.appbuckets.com/chat/${userId}`);
//             if (!response.ok) {
//                 throw new Error('Failed to fetch session titles');
//             }
//             const data = await response.json();
//             const sessionTitleMap = new Map();
//             data.forEach(chat => {
//                 if (!sessionTitleMap.has(chat.sessionId)) {
//                     const trimmedTitle = chat.title.trim();
//                     const truncatedTitle = trimmedTitle.substring(0, 22);
//                     sessionTitleMap.set(chat.sessionId, truncatedTitle);
//                 }
//             });
//             setSessionTitles(Array.from(sessionTitleMap.entries()));
//         } catch (error) {
//             console.error('Error fetching session titles:', error);
//         }
//     };

//     const handledownloadClick = async () => {
//         try {
//             const response = await fetch(`https://mct-chatbot.appbuckets.com/downloadchat/${userId}`);
//             if (response.ok) {
//                 const chatLogs = await response.json();
//                 const doc = new jsPDF();
//                 doc.setFontSize(18);
//                 doc.text('Chat History', 14, 22);

//                 const tableColumn = ["Timestamp", "Title", "Message"];
//                 const tableRows = [];
//                 chatLogs.forEach(chat => {
//                     const chatData = [
//                         new Date(chat.timestamp).toLocaleString(),
//                         chat.title,
//                         chat.message,
//                     ];
//                     tableRows.push(chatData);
//                 });

//                 doc.autoTable({
//                     head: [tableColumn],
//                     body: tableRows,
//                     startY: 30,
//                     styles: { cellPadding: 3, fontSize: 10 },
//                     columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 30 }, 2: { cellWidth: 'auto' } },
//                 });

//                 doc.save('chat-history.pdf');
//             } else {
//                 console.error('Failed to download chats');
//             }
//         } catch (error) {
//             console.error('Error downloading chats:', error);
//         }
//     };

//     return (
//         <aside className={`sidebar-styled ${isOpen ? 'open' : 'closed'} ${isLightTheme ? 'sidebar-styled-light' : ''}`}>
//             <div style={{ alignItems: 'center' }}>
//                 <div className='themesetting'>
//                     <List weight='bold' className='hiddenlist' size={25} onClick={toggleSidebar} />
//                     {/* <input type="checkbox" className="theme-checkbox" onChange={toggleTheme} checked={isLightTheme} /> */}
//                 </div>

//                 <div className={`${isLightTheme ? 'buttonnewchat' : 'buttonnewchat'}`}>
//                     <button onClick={handleNewChat}><Plus weight='bold' size={20} />
//                         <span>New chat</span>
//                     </button>
//                 </div>
//                 <div style={{ padding: '16px', paddingTop: '10px', paddingBottom: '5px' }}><span>Recent</span></div>
//                 <div className='recents'>
//                     {sessionTitles.map(([sessionId, title], index) => (
//                         <div key={index} className={`${isLightTheme ? 'recent-chat-light' : 'recent-chat'}`} style={{ alignItems: 'center' }} onClick={() => onSessionTitleClick(sessionId)}>
//                             <Chat weight='bold' size={20} /> <span style={{ textAlign: 'left' }}>{title}</span>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <div className='sidebar-bottom'>
//                 <div onClick={handledownloadClick}>
//                     <Download weight='bold' size={20} />
//                     <span>Download Chats</span>
//                 </div>
//                 <div onClick={handlefaqsClick}>
//                     <Question weight='bold' size={20} />
//                     <span>FAQS</span>
//                 </div>

//                 <div style={{ display: 'flex', alignItems: 'center' }}>
//                     <div onClick={handleSettingsClick} style={{ display: 'flex', alignItems: 'center' }}>
//                         <Gear weight='bold' size={20} />
//                         <span>Settings</span>
//                     </div>
//                 </div>
//                 <p className='messagemct'>Code by @MCT IT Solutions</p>
//             </div>

//             {isSettingsOpen && (
//                 <div className="settings-card">
//                     <h3>Settings</h3>
//                     <div className='themesetting'>
//                         <span>Light Theme:</span>
//                         <input type="checkbox" className="theme-checkbox" onChange={toggleTheme} checked={isLightTheme} />
//                     </div>
//                     <button onClick={() => setIsSettingsOpen(false)}>Close</button>
//                 </div>
//             )}
//         </aside>
//     );
// };

// export default Sidebar;
// import React, { useState, useEffect } from 'react';
// import { Chat, Question, Plus, List, Download, Gear } from 'phosphor-react';
// import '../styles/Sidebar.css';
// import { useNavigate } from 'react-router-dom';
// import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';
// import logodark from '../images/intellikb light bg.png';
// import lightlogo from '../images/intellikb light bg.png';

// const Sidebar = ({ isOpen, toggleSidebar, handleNewChat, previousChats, onSessionTitleClick, isLightTheme, toggleTheme, onFetchSessionTitles }) => {
//     const navigate = useNavigate();
//     const [sessionTitles, setSessionTitles] = useState([]);
//     const [userId, setUserId] = useState('');
//     const [isSettingsOpen, setIsSettingsOpen] = useState(false);

//     useEffect(() => {
//         const storedUser = localStorage.getItem('user');
//         if (storedUser) {
//             const parsedUser = JSON.parse(storedUser);
//             if (parsedUser.userid !== userId) {
//                 setUserId(parsedUser.userid);
//             }
//         }
//     }, [userId]);

//     const handleSettingsClick = () => {
//         setIsSettingsOpen(!isSettingsOpen);
//     };

//     const handlefaqsClick = () => {
//         navigate('/faqs');
//     };

//     const fetchSessionTitles = async () => {
//         if (!userId) return;

//         try {
//             const response = await fetch(`https://mct-chatbot.appbuckets.com/chat/${userId}`);
//             if (!response.ok) {
//                 throw new Error('Failed to fetch session titles');
//             }
//             const data = await response.json();
//             const sessionTitleMap = new Map();
//             data.forEach(chat => {
//                 if (!sessionTitleMap.has(chat.sessionId)) {
//                     const trimmedTitle = chat.title.trim();
//                     const truncatedTitle = trimmedTitle.substring(0, 22);
//                     sessionTitleMap.set(chat.sessionId, truncatedTitle);
//                 }
//             });
//             setSessionTitles(Array.from(sessionTitleMap.entries()));
//         } catch (error) {
//             console.error('Error fetching session titles:', error);
//         }
//     };

//     const handledownloadClick = async () => {
//         try {
//             const response = await fetch(`https://mct-chatbot.appbuckets.com/downloadchat/${userId}`);
//             if (response.ok) {
//                 const chatLogs = await response.json();
//                 const doc = new jsPDF();
//                 doc.setFontSize(18);
//                 doc.text('Chat History', 14, 22);

//                 const tableColumn = ["Timestamp", "Title", "Message"];
//                 const tableRows = [];
//                 chatLogs.forEach(chat => {
//                     const chatData = [
//                         new Date(chat.timestamp).toLocaleString(),
//                         chat.title,
//                         chat.message,
//                     ];
//                     tableRows.push(chatData);
//                 });

//                 doc.autoTable({
//                     head: [tableColumn],
//                     body: tableRows,
//                     startY: 30,
//                     styles: { cellPadding: 3, fontSize: 10 },
//                     columnStyles: { 0: { cellWidth: 30 }, 1: { cellWidth: 30 }, 2: { cellWidth: 'auto' } },
//                 });

//                 doc.save('chat-history.pdf');
//             } else {
//                 console.error('Failed to download chats');
//             }
//         } catch (error) {
//             console.error('Error downloading chats:', error);
//         }
//     };

//     return (
//         <aside className={`sidebar-styled ${isOpen ? 'open' : 'closed'} ${isLightTheme ? 'sidebar-styled-light' : ''}`}>
//             <div style={{ alignItems: 'center' }}>
//                 <div className='themesetting'>
//                     <List weight='bold' className='hiddenlist' size={25} onClick={toggleSidebar} />
//                     <input type="checkbox" className="theme-checkbox" onChange={toggleTheme} checked={isLightTheme} />
//                 </div>

//                 <div className={`${isLightTheme ? 'buttonnewchat' : 'buttonnewchat'}`}>
//                     <button onClick={handleNewChat}><Plus weight='bold' size={20} />
//                         <span>New chat</span>
//                     </button>
//                 </div>
//                 <div style={{ padding: '16px', paddingTop: '10px', paddingBottom: '5px' }}><span>Recent</span></div>
//                 <div className='recents'>
//                     {sessionTitles.map(([sessionId, title], index) => (
//                         <div key={index} className={`${isLightTheme ? 'recent-chat-light' : 'recent-chat'}`} style={{ alignItems: 'center' }} onClick={() => onSessionTitleClick(sessionId)}>
//                             <Chat weight='bold' size={20} /> <span style={{ textAlign: 'left' }}>{title}</span>
//                         </div>
//                     ))}
//                 </div>
//             </div>
//             <div className='sidebar-bottom'>
//                 <div onClick={handledownloadClick}>
//                     <Download weight='bold' size={20} />
//                     <span>Download Chats</span>
//                 </div>
//                 <div onClick={handlefaqsClick}>
//                     <Question weight='bold' size={20} />
//                     <span>FAQS</span>
//                 </div>

//                 <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
//                     <div onClick={handleSettingsClick} style={{ display: 'flex', alignItems: 'center' }}>
//                         <Gear weight='bold' size={20} />
//                         <span>Settings</span>
//                     </div>
//                     {isSettingsOpen && (
//                         <div className="settings-card">
//                             <div className='themesetting'>
//                                 <span>Light Theme:</span>
//                                 <input type="checkbox" className="theme-checkbox" onChange={toggleTheme} checked={isLightTheme} />
//                             </div>
//                             <button onClick={() => setIsSettingsOpen(false)}>Close</button>
//                         </div>
//                     )}
//                 </div>
//                 <p className='messagemct'>Code by @MCT IT Solutions</p>
//             </div>
//         </aside>
//     );
// };

// export default Sidebar;

